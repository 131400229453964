import React from 'react'
import {Link} from 'gatsby'
// import shape1 from '../../assets/images/shape/shape1.svg'
// import shape2 from '../../assets/images/shape/shape2.png'
// import shape3 from '../../assets/images/shape/shape3.png'
import Bubbles2 from './Bubbles2';

const PageBanner2 = ({pageTitle, homePageUrl, homePageText, activePageText}) => {
    return (
        <div className="services-page-title-area2">
         <Bubbles2 /> 
            <div className="page-title-mask"> 
            
                <div className="container">
                    <div className="page-title-content">
                        <h2 style={{color: 'white'}}>{pageTitle}</h2>
                        <ul>
                            <li>
                                <Link style={{color: 'white'}} to={homePageUrl}>
                                    {homePageText}
                                </Link>
                            </li>
                            {/* <li>{activePageText}</li> */}
                        </ul>
                    </div>
                </div>

                {/* <div className="shape-img1">
                    <img src={shape1} alt="banner" />
                </div>
                <div className="shape-img2">
                    <img src={shape2} alt="banner" />
                </div>
                <div className="shape-img3">
                    <img src={shape3} alt="banner" />
                 
                </div> */}
            </div> 
        </div>
    )
}

export default PageBanner2;