import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner2 from '../components/Services/PageBanner2'
import Footer from "../components/App/Footer"
import ServicesOne from '../components/services/ServicesOne'
// import StartProject from '../components/Common/StartProject'


const Services2 = () => {
    return (
        <Layout>
            <div className="darknav"><Navbar /></div>
            <PageBanner2
                pageTitle="Cyber Security Services" 
                homePageText="Information &amp; Cyber Security Services" 
                homePageUrl="/" 
                activePageText="Services" 
            />
            <ServicesOne />
            {/* <StartProject /> */}
            <Footer />
        </Layout>
    );
}

export default Services2